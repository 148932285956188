body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

header {
  margin-top: 40px;
}

.MCContent {
  margin-top: 40px;
}

#BetaBanner {
  position: fixed;
  top: 0px;
  left: 0px;
}

#BetaBanner span {
  padding-top: 10px;
  position: absolute;
  top: 2px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.ReactModal__Content {
  outline: none;
}

.Modal {
  position: absolute;
  top: 180px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  padding: 16px;
  max-height: 300px;
  background-color: white;
  border-radius: 2px;
  animation: "bounceIn .5s ease-in-out";
  overflow:auto;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.3);
}

.ReactModal__Content {
  opacity: 0;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transition: opacity 150ms;
}

.ReactModal__Content--before-close {
  opacity: 0;
}

.Centered {
  text-align: center;
}

@media (max-width: 767px) {
  .layoutContentWrapper {
    padding-top: 12px;
  }
}
