.BetaBanner[class] {
    background: repeating-linear-gradient(
        315deg,
        yellow,
        yellow 10px,
        black 10px,
        black 20px
    );
    text-align: center;
    width: 100%;
    height: 40px;
}

.BetaBanner[class] span.BannerSpan[class] {
    width: initial !important;
    position: initial !important;
    padding-top: 0px !important;
    margin-top: 3px !important;
    padding: 7px !important;
    color: white;
    display: inline-block;
    width: initial;
    background-color: rgb(64, 64, 64);
    border-radius: 4px;
    text-shadow: 0px 0px 10px black;
    font-weight: bold;
}